<template>
  <div class="w-full h-full bg-success">
    <div class="router-content">
      <router-view />
    </div>

    <div class="fixed w-full bottom-0 bg-success">
      <the-footer />
    </div>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import TheFooter from "./components/TheFooter.vue";

export default {
  components: { TheFooter },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },
};
</script>

<style lang="scss" scoped>
.router-content {
  width: 100%;
  height: calc(100vh - 70px);
  overflow: auto;
}

.router-content::-webkit-scrollbar {
  width: 0em;
}
</style>
