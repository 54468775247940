<template functional>
  <footer
    class="the-footer block text-center"
    :class="classes"
  >
    <span class="relative text-center inline-block mr-2">
      <a
        href="https://wa.me/message/XUI5GC2D33Q5B1"
        rel="nofollow"
        class="link"
      >
        <img
          class="whatsapp-logo inline-block"
          :src="require('@/assets/images/logo/whatsapp.png')"
          width="32"
        >
        Feedback</a>
    </span>
    <span class="relative text-center inline-block">
      &copy; Leecom Scada Systems Pvt Ltd.

      <img
        class="leecom-logo inline-block"
        :src="require('@/assets/images/logo/leecom-logo.png')"
      >
    </span>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  props: {
    classes: {
      type: String,
    },
  },
};
</script>

<style scoped>
.the-footer {
  background-color: transparent;
  color: #000;
  padding: 5px 0;
}

.the-footer a {
  color: #0035ff;
}

.whatsapp-logo {
  margin-top: -5px;
}
</style>
